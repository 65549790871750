$(window).ready(function(){
    if(typeof $('video')[0] !== 'undefined')
        $('video')[0].play();
});
$(document).ready(function () {
    function divideNumberByPieces(x, delimiter) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimiter || " ");
    }

    var myLazyLoad = new LazyLoad({
        elements_selector: ".lazy"
    });

    // if($(document).innerWidth() > 1024) {
    //     var minHeight = 0;
    //     $(".about-item__text").each(function (index, el) {
    //         if($(el).innerHeight() > minHeight) {
    //             minHeight = $(el).innerHeight();
    //         }
    //     });
    //
    //     $(".about-item__text").css({"min-height": minHeight + "px"});
    // }

    $(".header-lang").click(function () {
        $(".header-lang__options").toggle(500);
    });

    $(document).mouseup(function (e){
        var div = $(".header-lang");
        if (!div.is(e.target)
            && div.has(e.target).length === 0) {
            $(".header-lang__options").hide();
        }
    });

    $(".about-slider").slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        fade: false,
        dots: true,
        adaptiveHeight: true,
        prevArrow: "<div class='slider__arrow slider__arrow-prev'></div>",
        nextArrow: "<div class='slider__arrow slider__arrow-next'></div>",
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }
        ]
    });

    $(".about-slider").slick("slickUnfilter");
    $(".about-slider").slick("slickFilter", ".about-slider__slide_song");

    $(".about-tabs__item").click(function () {
        var $this  = $(this),
            filter = $this.data("filter");

        $(".about-tabs__item").removeClass("about-tabs__item_active");
        $this.addClass("about-tabs__item_active");

        $(".about-slider").slick("slickUnfilter");
        $(".about-slider").slick("slickFilter", ".about-slider__slide_" + filter);
    });

    $(".emotions-slider").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: false,
        dots: true,
        adaptiveHeight: true,
        prevArrow: "<div class='slider__arrow slider__arrow-prev'></div>",
        nextArrow: "<div class='slider__arrow slider__arrow-next'></div>",
    });

    $(".can-slider").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: false,
        dots: true,
        adaptiveHeight: true,
        prevArrow: "<div class='slider__arrow slider__arrow-prev'></div>",
        nextArrow: "<div class='slider__arrow slider__arrow-next'></div>",
    });

    $(".photos-slider").slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        fade: false,
        dots: true,
        adaptiveHeight: true,
        prevArrow: "<div class='slider__arrow slider__arrow-prev'></div>",
        nextArrow: "<div class='slider__arrow slider__arrow-next'></div>",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }
        ]
    });

    $(".videos-slider").each(function (i, el) {
        var length = $(el).find(".videos-slider__slide").length;

        if(length > 1) {
            $(el).slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                fade: false,
                dots: true,
                adaptiveHeight: true,
                prevArrow: "<div class='slider__arrow slider__arrow-prev'></div>",
                nextArrow: "<div class='slider__arrow slider__arrow-next'></div>",
            });
        }
    });

    $(".questions__item").click(function () {
        $this = $(this);

        if ($this.hasClass("questions__item_opened")) {
            $this.removeClass("questions__item_opened");
            $this.find(".questions__item-body").slideUp();
        } else {
            $this.addClass("questions__item_opened");
            $this.find(".questions__item-body").slideDown();
        }
    });

    $("body").on("click", ".open-popup", function (e) {
        e.preventDefault();

        var el = $(this).data("popup");
        var $this = $(this);

        $.fancybox.open($(".popup-" + el), {
            touch: true,
            helpers: {
                thumbs: {
                    width: 50,
                    height: 50
                },
                overlay: {
                    locked: true
                }
            },
            beforeShow: function () {
            },
            afterShow: function () {
            }
        });
    });


    $(".order-item__product-slider").each(function (i, el) {
        var length = $(el).find(".order-item__product-slide").length;

        if(length > 1) {
            $(el).slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                fade: false,
                dots: true,
                adaptiveHeight: true,
                prevArrow: "<div class='slider__arrow slider__arrow-prev order-item__product-slider-arrow order-item__product-slider-arrow_prev'></div>",
                nextArrow: "<div class='slider__arrow slider__arrow-next order-item__product-slider-arrow order-item__product-slider-arrow_next'></div>",
            });
        }
    });

    function getFormObject(formArray) {
        var obj = {};
        $.each(formArray, function (i, pair) {
            var cObj = obj, pObj, cpName;
            $.each(pair.name.split("."), function (i, pName) {
                pObj = cObj;
                cpName = pName;
                cObj = cObj[pName] ? cObj[pName] : (cObj[pName] = {});
            });
            pObj[cpName] = pair.value;
        });
        return obj;
    }

    function submitForm(form) {
        var formData = getFormObject($(form).serializeArray());

        axios({
            method: "post",
            url: "./sender.php",
            data: formData,
            config: {headers: {"Content-Type": "application/x-www-form-urlencoded"}}
        }).then(function (response) {
            // console.log(response.data);
            window.location.replace("./thanks.html");
        }).catch(function (response) {});
    }

    // Формы
    $("form").submit(function () {
        return false;
    });

    $("form").each(function () {
        $(this).validate({
            rules: {
                name: {
                    required: true,
                },
                phone: {
                    required: true,
                },
                message: {
                    required: true,
                },
            },
            submitHandler: function (form) {
                submitForm(form);
            }
        });
    });

    $(".form__input_date").datepicker();

    function onScroll() {
        var scroll_top = $(document).scrollTop();

        var $header = $(".header");

        if (scroll_top > $(".promo").innerHeight() - $(".header").innerHeight()) {
            if (!$header.hasClass("header_fixed")) {
                $header.hide().fadeIn().addClass("header_fixed");
            }
        } else {
            $header.removeClass("header_fixed");
        }
    }
    onScroll();
    $(document).on("scroll", onScroll);

    $(".header__open-menu").click(function () {
        var $this = $(this);

        if($this.hasClass("header__open-menu_opened")) {
            $this.removeClass("header__open-menu_opened");
            $(".header__menu").removeClass("header__menu_opened").fadeOut(300);
            $(".header").removeClass("header_theme_white");
        } else {
            $this.addClass("header__open-menu_opened");
            $(".header__menu").addClass("header__menu_opened").fadeIn(300);
            $(".header").addClass("header_theme_white");
        }
    });

    $(".scroll-to").click(function () {
        var to = $(this).data("to") || $(this).attr("href");

        if ($(to).length > 0) {
            $.fancybox.close();

            if (event) event.preventDefault();

            var top = $(to).offset().top + 1;

            top = top - $(".header").innerHeight();

            if (to == "#promo") {
                top = 0;
            }

            if($("html").innerWidth() < 1024) {
                $(".header-menu").hide();
                $(".header").removeClass("header_theme_white");

                to += $(".header-menu").innerHeight();
            }

            $(".header__open-menu").removeClass("header__open-menu_opened");
            $(".header").removeClass("header_theme_white");

            $("body, html").animate({scrollTop: top}, 500);
        }
    });

    /**
     * Калькулятор
     */

    /**
     * Объект со значениями
     */
    var calcObj = {
        startStep: 1,
        processStep: 1,
        gateway: [], // Выбранные путь
        city: "", // Город,
        tarif: "", // Тариф
        services: []
    };
    var prices = {};

    // Завершающий шаг
    function finishStart() {
        console.log(calcObj);

        $(".order-item__selected-tarif").text(calcObj.tarif);

        $(".order-items_start .order-item").hide();
        $(".order-items_start .order-item[data-step=startFinish]").fadeIn();
    }

    function goToPrecess() {
        $(".order-items_start .order-item").hide();
        var id = calcObj.gateway.join("-");
        $(".order-items_process-way[data-id=" + id + "] .order-item[data-step=1]").fadeIn();

        $(".order-item__back").fadeIn();

        var top = $(".order").offset().top + 1;
        $("body, html").animate({scrollTop: top}, 500);

        var productTitle = "",
            productPrice = "";

        switch (id) {
            case "1-1":
                    productTitle = "Демо-версия";
                    productPrice = 190;
                break;
            case "1-2-1":
                    productTitle = "Караоке";
                    productPrice = prices.price_karaoke;
                break;
            case "1-2-2":
                    productTitle = "Караоке с текстом";
                    productPrice = prices.price_karaoke_text;
                break;
            case "1-2-3":
                    productTitle = "Эксклюзив";
                    productPrice = prices.price_exclusive;
                break;
            case "1-2-4":
                    productTitle = "Песня под ключ";
                    productPrice = prices.price_song;
                break;
            case "2-1":
                    productTitle = "Демо-версия";
                    productPrice = 190;
                break;
            case "2-2-1":
                    productTitle = "Лайт";
                    productPrice = prices.price_light;
                break;
            case "2-2-2":
                    productTitle = "Профи";
                    productPrice = prices.price_pros;
                break;
        }

        calcObj.services.push({
            title: productTitle,
            price: productPrice
        });
    }

    /**
     * НАЧАЛО
     */
    /**
     * Первый шаг
     */
    $(".order-item__city").on("change", function() {
        calcObj.city = this.value;

        if(calcObj.city == "Москва" || calcObj.city == "Санкт-Петербург") {
            prices = {
                price_karaoke:             3900, // Караоке
                price_karaoke_text:        4900, // Караоке с текстом
                price_exclusive:           5900, // Эксклюзив
                price_song:                9900, // Песня под ключ
                price_pros:                4900, // Профи
                price_light:               1900, // Лайт
                price_video_collage:       2000, // Видео коллаж
                price_video_studio:        9900, // Видео в студии
                price_video_studio_street: 12900, // Видео "студия + улица"
                price_video_slideshow:     2000,  // Шикарное видео-слайдшоу из ваших фотографий под заказанную песню
                price_video_inserts:       990,   // Видео-вставки в Вашем слайд-шоу
                price_voice:               490,   // Ваш личный голос в начале заказанной вами песни
                price_back:                990    // Шикарнейший бэк-вокал на припев вашей песни
            }
        } else {
            prices = {
                price_karaoke:             2900, // Караоке
                price_karaoke_text:        3900, // Караоке с текстом
                price_exclusive:           4900, // Эксклюзив
                price_song:                7900, // Песня под ключ
                price_pros:                4900, // Профи
                price_light:               1900, // Лайт
                price_video_collage:       2000, // Видео коллаж
                price_video_studio:        7900, // Видео в студии
                price_video_studio_street: 10900, // Видео "студия + улица"
                price_video_slideshow:     2000,  // Шикарное видео-слайдшоу из ваших фотографий под заказанную песню
                price_video_inserts:       990,   // Видео-вставки в Вашем слайд-шоу
                price_voice:               490,   // Ваш личный голос в начале заказанной вами песни
                price_back:                990    // Шикарнейший бэк-вокал на припев вашей песни
            }
        }

        $("[data-price-karaoke] .price").text(divideNumberByPieces(prices.price_karaoke));
        $("[data-price-karaoke-text] .price").text(divideNumberByPieces(prices.price_karaoke_text));
        $("[data-price-exclusive] .price").text(divideNumberByPieces(prices.price_exclusive));
        $("[data-price-song] .price").text(divideNumberByPieces(prices.price_song));
        $("[data-price-pros] .price").text(divideNumberByPieces(prices.price_pros));
        $("[data-price-light] .price").text(divideNumberByPieces(prices.price_light));
        $("[data-price-video-collage] .price").text(divideNumberByPieces(prices.price_video_collage));
        $("[data-price-video-studio] .price").text(divideNumberByPieces(prices.price_video_studio));
        $("[data-price-video-studio-street] .price").text(divideNumberByPieces(prices.price_video_studio_street));

        $("[data-price-video-slideshow] .price").text(divideNumberByPieces(prices.price_video_slideshow));
        $("[data-price-video-inserts] .price").text(divideNumberByPieces(prices.price_video_inserts));
        $("[data-price-voice] .price").text(divideNumberByPieces(prices.price_voice));
        $("[data-price-back] .price").text(divideNumberByPieces(prices.price_back));

        calcObj.startStep++;

        $(".order-items_start .order-item").hide();
        $(".order-items_start .order-item[data-step=2]").fadeIn();
    });

    /**
     * Остальные шаги
     */
    $(".order-item__select-option").click(function () {
        var $this = $(this),
            id    = $this.data("id").toString(),
            title = $this.data("title");

        // Первый шаг
        if(calcObj.startStep == 1) {
            return;
        }

        // Второй шаг
        if(calcObj.startStep == 2) {
            calcObj.gateway[0] = id;

            if(calcObj.gateway[0] == 1 || calcObj.gateway[0] == 2) {
                calcObj.startStep++;

                $(".order-items_start .order-item").hide();
                $(".order-items_start .order-item[data-step=" + calcObj.startStep + "-" + calcObj.gateway.join("-") + "]").fadeIn();
            }

            var top = $(".order").offset().top + 1;
            $("body, html").animate({scrollTop: top}, 500);

            return;
        }

        // Третий шаг
        if(calcObj.startStep == 3) {
            calcObj.gateway[1] = id;

            if(calcObj.gateway[0] == 1) {
                if(calcObj.gateway[1] == 1) {
                    calcObj.tarif = title;
                    finishStart();
                } else {
                    calcObj.startStep++;

                    $(".order-items_start .order-item").hide();
                    $(".order-items_start .order-item[data-step=" + calcObj.startStep + "-" + calcObj.gateway.join("-") + "]").fadeIn();
                }
            }

            if(calcObj.gateway[0] == 2) {
                if(calcObj.gateway[1] == 1) {
                    calcObj.tarif = title;
                    finishStart();
                } else {
                    calcObj.startStep++;

                    $(".order-items_start .order-item").hide();
                    $(".order-items_start .order-item[data-step=" + calcObj.startStep + "-" + calcObj.gateway.join("-") + "]").fadeIn();
                }
            }

            var top = $(".order").offset().top + 1;
            $("body, html").animate({scrollTop: top}, 500);

            console.log(calcObj.gateway);

            return;
        }

        // Четверый шаг
        // 4 1 2
        if(calcObj.startStep == 4) {
            calcObj.gateway[2] = id;

            calcObj.tarif = title;
            finishStart();

            var top = $(".order").offset().top + 1;
            $("body, html").animate({scrollTop: top}, 500);

            return;
        }
    });

    /**
     * Выбор дополнения
     */
    $(".order-item__product-select").click(function () {
        var $this = $(this),
            $product = $this.closest(".order-item__product"),
            productTitle = $product.find(".order-item__product-title").text(),
            productPrice = parseInt($product.find(".order-item__product-price .price").text());

        calcObj.services.push({
            title: productTitle,
            price: productPrice
        });

        nextStep();
    });

    /**
     * Конец общий блоков
     */
    $(".order-item__finish-start").click(function () {
        goToPrecess();
    });

    function isValidEmail(email) {
        // return /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/.test(email);
        return email.length > 5;
    }
    function isValidPhone(phone) {
        return /(\d){1,13}$/.test(phone);
    }

    function nextStep() {
        var wayId            = calcObj.gateway.join("-"),
            processStep      = calcObj.processStep,
            $thisProcessStep = $(".order-items_process-way[data-id=" + wayId + "] .order-item[data-step=" + processStep  + "]");

        if(wayId == "1-2-3") {
            switch (processStep) {
                case 3:
                        if($thisProcessStep.find("input[name=demo]").is(":checked")) {
                            calcObj.processStep += 1;
                            processStep = calcObj.processStep;
                        }
                    break;
            }
        }

        if(wayId == "1-1") {
            $(".order-items__finish-services_2").show();
        }
        if(wayId == "2-1") {
            $(".order-items__finish-services_1").show();
        }

        var nextProcessStep = processStep + 1,
            $nextProcessStep = $(".order-items_process-way[data-id=" + wayId + "] .order-item[data-step=" + nextProcessStep  + "]");

        // Проверка каждого поля на заполненность
        var isValid = true;
        $thisProcessStep.find(".required").each(function (i, el) {
            var isValidInput = true;

            if($(el).val().length == 0) {
                $(el).addClass("form__input_error");
                isValid = false;
                isValidInput = false;
            }

            if($(el).attr("name") == "phone") {
                if(!isValidPhone($(el).val())) {
                    $(el).addClass("form__input_error");
                    isValid = false;
                    isValidInput = false;
                }
            }
            if($(el).attr("name") == "email") {
                $(el).val($(el).val().toLowerCase());
                if(!isValidEmail($(el).val())) {
                    $(el).addClass("form__input_error");
                    isValid = false;
                    isValidInput = false;
                }
            }

            if(isValidInput) {
                $(el).removeClass("form__input_error");
            }
        });

        // Перебор всех полей в шаге
        if(isValid) {
            var dataForm = $thisProcessStep.find("input, textarea, select").serializeArray(),
                dataObject = {};

            $(dataForm).each(function(index, obj) {
                dataObject[obj.name] = obj.value;
            });

            $.extend(calcObj, dataObject)

            console.log(calcObj);
        }

        // Переход к след. шагу или результат калькулятора
        if(isValid) {
            if($thisProcessStep.find("[name=phone]").length) {
                var formData = JSON.stringify(calcObj);
                axios({
                    method: "post",
                    url: "./sender.php",
                    data: formData,
                    config: {headers: {"Content-Type": "multipart/form-data"}},
                }).then(function (response) {
                    console.log(response.data);
                }).catch(function (response) {
                });
            }

            if ($nextProcessStep.length) {
                calcObj.processStep++;

                $(".order-items_process .order-item").hide();
                $nextProcessStep.fadeIn();

                if($nextProcessStep.find(".slider").length) {
                    $nextProcessStep.find(".slider").slick("slickGoTo", 0);
                }

                var top = $(".order").offset().top + 1;
                $("body, html").animate({scrollTop: top}, 500);
            } else {
                finish();
            }
        }
    }
    function prevStep() {
        var wayId            = calcObj.gateway.join("-"),
            processStep      = calcObj.processStep,
            $thisProcessStep = $(".order-items_process-way[data-id=" + wayId + "] .order-item[data-step=" + processStep  + "]");

        var prevProcessStep = processStep - 1,
            $prevProcessStep = $(".order-items_process-way[data-id=" + wayId + "] .order-item[data-step=" + prevProcessStep  + "]");

        if($prevProcessStep.length) {
            calcObj.processStep--;

            $(".order-items_process .order-item").hide();
            $prevProcessStep.fadeIn();
        }
    }

    $(".order-item__next-step").click(function () {
        nextStep();
    });

    $(".order-item__back").click(function () {
       prevStep();
    });

    /**
     * Выбор артиста
     */
    $(".order-item__artist-btn").click(function () {
        var $artist = $(this).closest(".order-item__artist"),
            name    = $artist.find(".order-item__artist-name").text(),
            picture = $artist.find(".order-item__artist-picture").attr("src");

        $(".order-items__finish-artist").show();

        $(".order-item__r-artist-name").text(name);
        $(".order-item__r-artist-picture").attr("src", picture);

        calcObj.artist = name;

        nextStep();
    });

    function calc() {
        var totalPrice = 0;

        $.each(calcObj.services, function(key, value) {
            totalPrice += parseInt(value.price);
        });

        if(calcObj.isSpeed) {
            totalPrice = totalPrice * 1.5;
        }

        calcObj.priceTotal = parseInt(totalPrice);
        $(".order-item__pay-price").text(divideNumberByPieces(totalPrice));
    }
    $(".order-item__speed-checkbox[name=isSpeed]").on("change", function () {
        if($(this).is(":checked")) {
            calcObj.isSpeed = true;
        } else {
            calcObj.isSpeed = false;
        }

        calc();
    });

    /**
     * Результаты калькулятора
     */
    function addInfo(title, value) {
        var $clone = $(".order-item__cost-item_template")
            .clone()
            .removeClass("order-item__cost-item_template");

        $clone.find(".order-item__cost-item-title").text(title);
        $clone.find(".order-item__cost-item-value").text(value);

        $clone.appendTo(".order-item__cost");
    }
    function finish() {
        $(".order-item__back").hide();
        $(".order-items_process .order-item").hide();

        addInfo("Ваш мобильный:", calcObj.phone);
        addInfo("Ваша электронная почта:", calcObj.email);

        if(calcObj.name_for !== undefined) {
            addInfo("Кому посвещаете песню:", calcObj.name_for);
        }
        if(calcObj.event !== undefined) {
            addInfo("На какое событие:", calcObj.event);
        }
        if(calcObj.date !== undefined) {
            addInfo("Какого числа событие:", calcObj.date);
        }
        if(calcObj.name_yourself !== undefined) {
            addInfo("Ваше имя:", calcObj.name_yourself);
        }

        var wayId = calcObj.gateway.join("-"),
            $way  = $(".order-items_process-way[data-id=" + wayId + "]");

        // Выбранные доп. услуги
        if($way.find("input[name=isVideoSlideOption]").is(":checked")) {
            calcObj.services.push({
                title: "Видео-слайдшоу из ваших фотографий",
                price: prices.price_video_slideshow
            });

        }
        if($way.find("input[name=isVideoOption]").is(":checked")) {
            calcObj.services.push({
                title: "Видео-вставки в Вашем слайд-шоу",
                price: prices.price_video_inserts
            });
        }
        if($way.find("input[name=isVoiceOption]").is(":checked")) {
            calcObj.services.push({
                title: "Ваш личный голос в начале заказанной вами песни",
                price: prices.price_voice
            });
        }
        if($way.find("input[name=isBackOption]").is(":checked")) {
            calcObj.services.push({
                title: "Шикарнейший бэк-вокал на припев вашей песни",
                price: prices.price_back
            });
        }

        var i = 0;
        $.each(calcObj.services, function(key, value) {
            if(i !== 0) {
                addInfo(value.title, divideNumberByPieces(value.price) + " руб.");
            }

            i++;
        });

        calc();

        $(".order-items_finish .order-item_finish").fadeIn();

        var top = $(".order").offset().top + 1;
        $("body, html").animate({scrollTop: top}, 500);
    }

    function makePayment(amount, orderId, description, name, email, phone) {
        var params = {
            //Код терминала (обязательный параметр), выдается банком.
            TerminalKey: "1486033117318DEMO",
            //Сумма заказа в копейках (обязательный параметр)
            Amount: amount,
            //Номер заказа (если не передан, принудительно устанавливается timestamp)
            OrderId: orderId,
            //Описание заказа (не обязательный параметр)
            Description: description,
            //Дополнительные параметры платежа
            DATA: "Email=" + email + "|Phone=" + phone + "|Name=" + name,
            //Флаг открытия платежной формы во фрейме
            Frame: true
        };
        doPay(params);
    }

    $(".order-item__payment").click(function () {
        formData =  JSON.stringify(calcObj);

        axios({
            method: "post",
            url: "./sender.php",
            data: formData,
            config: { headers: {"Content-Type": "multipart/form-data" }},
        }).then(function (response) {
            console.log(response.data);
            makePayment(calcObj.priceTotal * 100, null, null, calcObj.name, calcObj.email, calcObj.phone);
        }).catch(function (response) {});
    });
});
$(document).ready(function(){
    var prevtouch = 0;

    $(".aplayer__progress progress").each(function() {
        var $plrInstance = $(this).parents(".aplayer").find(".aplayer__track").get(0);

        $(this).slider({
            range: "min",
            min: 0,
            max: $plrInstance.duration,
            value: 0,
            step: 1,

            slide: function(event, ui) {
                if(event.originalEvent != undefined) {
                    $plrInstance.pause();
                    $plrInstance.currentTime = ui.value;
                    $plrInstance.play();
                }
            }
        })
    });

    $(document).on("click", ".aplayer__play", function(e){
        e.preventDefault();
        if ( $(this).hasClass("active") ) {
            $(this).removeClass("active");
            $(this).parents(".aplayer").find(".aplayer__track").get(0).pause();
        }
        else {
            $(".aplayer__play.active").removeClass("active");
            $(this).addClass("active");
            var sounds = document.getElementsByTagName("audio");
            for(i=0; i<sounds.length; i++) sounds[i].pause();
            $(this).parents(".aplayer").find(".aplayer__track").get(0).play();
        }
    });

    $(".aplayer__track").on("timeupdate", function(){
        var percentage = Math.floor((100 / $(this).get(0).duration) * $(this).get(0).currentTime);
        curtime = parseInt($(this).get(0).currentTime);
        $(this).parents(".aplayer").find(".aplayer__progress progress").val(percentage);

    });

    $(".aplayer__track").on("ended", function(){
        $(this).parents(".aplayer").find(".aplayer__progress progress").val("0");
        $(this).parents(".aplayer").find(".aplayer__track").get(0).pause();
        $(this).parents(".aplayer").find(".aplayer__track").get(0).currentTime = 0;
        $(".aplayer__play.active").removeClass("active");
    });

    $(document).on("click", ".aplayer__progress progress", function(e){
        var percent = e.offsetX / this.offsetWidth;

        $(this).parents(".aplayer").find(".aplayer__track").get(0).currentTime = parseFloat(percent * $(this).parents(".aplayer").find(".aplayer__track").get(0).duration);
        $(this).val( Math.floor(percent / 100) );
    });

    $(document).on("click", ".aplayer__volume progress", function(e){
        var percent = e.offsetX / this.offsetWidth;
        $(this).val(Math.round(10*percent));
        $(this).parents(".aplayer").find(".aplayer__track").get(0).volume = e.target.value / 10;
    });

    $(".aplayer").each(function (i, el) {
       $(el).find(".aplayer__track").get(0).volume = 0.3;
    });

    // $(document).on("touchmove", ".aplayer__volume progress", function(e) {
	//     console.log(1);
	// 	var touchData = e.originalEvent.touches[0].clientX;
    //
	// 	var progressWidth = $(this).outerWidth();
	// 	var progressX = $(this).offset().left;
    //
	// 	var touchWidth = touchData - progressX;
    //
	// 	console.log(touchWidth);
    //
	// 	var percent = touchWidth / progressWidth;
	// 	$(this).parents('.aplayer').find('.aplayer__track').get(0).currentTime = parseFloat(percent * $(this).parents('.aplayer').find('.aplayer__track').get(0).duration);
    //     $(this).val( Math.floor(percent / 100) );
	// 	$(this).parents('.aplayer').find('.aplayer__track').get(0).play();
	// });
});
